<template>
  <div class="logout"></div>
</template>

<script>
import { computed } from '@vue/composition-api';

import utils from '@/utils';

export default {
  name: 'Logout',

  components: {},

  setup(_, context) {
    const { $route, $router } = context.root;
    const bsId = computed(() => $route.query.bs_id);
    if (bsId.value) {
      utils.removeLocalStorage(bsId.value);
      $router.push({
        path: '/',
        query: { ...$route.query },
      });
    }

    return {};
  },
};
</script>

<style lang="scss" scoped>
.logout {
}
</style>
